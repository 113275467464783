import { render, staticRenderFns } from "./Ovi.html?vue&type=template&id=073d9923&scoped=true&"
import script from "./Ovi.vue?vue&type=script&lang=js&"
export * from "./Ovi.vue?vue&type=script&lang=js&"
import style0 from "./Ovi.scss?vue&type=style&index=0&id=073d9923&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "073d9923",
  null
  
)

export default component.exports