<template src="./Ovi.html"> </template>

<script>
import Unity from "vue-unity-webgl";
export default {
  name: "ovi",
  components: { Unity },
  data() {
    return {
      message: "",
      oviId: null
    };
  },
  destroyed() {
      location.reload();
  },
  created() {
    this.oviId = this.$route.params.id;
  },
  methods: {
    sendText() {
      this.$refs.myInstance.message("Bridge", "SendToUnity", this.oviId);
    },
    fullscreen() {
      this.$refs.myInstance.fullscreen()
    }
  }
};
</script>

<style lang="scss" scoped src="./Ovi.scss"></style>
